import styled from 'styled-components'

export const TeamContainer = styled.div`
    padding: 10;
`

export const TeamNameContainer = styled.div`
    height: 40px;
    margin: 12px;
    border-radius: 8px;
    background-color: lightgray;
    padding: 4px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.3rem;
`

export const AnswerImage = styled.img`
    width: 100%;
`

export const PhotoContent = styled.div`
`