import styled from 'styled-components'

export const BaseImage = styled.img`
    width 100%;
    height: 100%;
`

export const Container = styled.div`
    max-width: 384px;
    margin: 12px;
    background-color: white;
    padding: 8px;

    border-radius: 12px;
`

export const TitleContainer = styled.div`
    display: flex;
    padding: 3px;
    align-items: center;
`

export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: space-evently;
    padding: 4px;
    width: 100%;
`

export const ImageContainer = styled.div`
    width 100%;
    max-width: 380px;
    height: 380px;
    border-radius: 5px;
    overflow: hidden;
`