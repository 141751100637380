import { Button, CardActions, CardContent, Dialog, styled, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#8A2BE2'),
  backgroundColor: '#8A2BE2',
  '&:hover': {
    backgroundColor: '#9932CC',
  },
  "&:disabled": {
    backgroundColor: 'lightgray'
  }
}));

const TimeModals = ({ event, showActivePage, setTimeUp, setNotBegun }) => {
  const [showTimeWarningModal, setShowTimeWarningModal] = useState(false)
  const [showTimeUpModal, setShowTimeUpModal] = useState(false)

  const [showNotBegunModal, setShowNotBegunModal] = useState(false)
  const [showBeginningModal, setShowBeginngingModal] = useState(false)

  useEffect(() => {
    if (!event) return

    const timeUntilEnd = new Date(event.end_time).getTime() - new Date().getTime()
    if (timeUntilEnd <= 0) {
      if (!event.allow_late) setTimeUp(true)
      setShowTimeUpModal(true)
    } else {
      setTimeout(() => {
        if (!event.allow_late) setTimeUp(true)
        setShowTimeUpModal(true)
      }, timeUntilEnd)
      if (timeUntilEnd - 60000 > 0) {
        setTimeout(() => setShowTimeWarningModal(true), timeUntilEnd - 60000 * 5)
      }

      const timeUntilStart = new Date(event.start_time).getTime() - new Date().getTime()
      if (timeUntilStart > 0) {
        setShowNotBegunModal(true)
        if (!event.allow_early) setNotBegun(true)
        setTimeout(() => {
          setNotBegun(false)
          setShowBeginngingModal(true)
        }, timeUntilStart)
      }
    }
  }, [event])

  return (
    <>
      {showTimeWarningModal ? (
        <Dialog open={showTimeWarningModal} onClose={() => setShowTimeWarningModal(false)}>
          <CardContent>
            <Typography>There are only 5 minutes left!</Typography>
          </CardContent>
          <CardActions>
            <ColorButton variant='outlined' onClick={() => setShowTimeWarningModal(false)}>Okay</ColorButton>
          </CardActions>
        </Dialog>
      ) : null}

      {showTimeUpModal ? (
        <Dialog open={showTimeUpModal} onClose={() => setShowTimeUpModal(false)}>
          <CardContent>
            <Typography>This event is over! You can try to keep going, or view all the photos from this event!</Typography>
          </CardContent>
          <CardActions>
            <ColorButton variant='outlined' onClick={() => setShowTimeUpModal(false)}>Okay</ColorButton>
            <ColorButton variant='outlined' onClick={() => {
              showActivePage(2)
              setShowTimeUpModal(false)
            }}>View Hunt Photos</ColorButton>
          </CardActions>
        </Dialog>
      ) : null}

      {showNotBegunModal ? (
        <Dialog open={showNotBegunModal} onClose={() => setShowNotBegunModal(false)}>
          <CardContent>
            <Typography>This hunt hasn't started! Hold steady until we begin.</Typography>
          </CardContent>
          <CardActions>
            <ColorButton variant='outlined' onClick={() => setShowNotBegunModal(false)}>Okay</ColorButton>
          </CardActions>
        </Dialog>
      ) : null}

      {showBeginningModal ? (
        <Dialog open={showBeginningModal} onClose={() => setShowBeginngingModal(false)}>
          <CardContent>
            <Typography>WE START NOW!!</Typography>
          </CardContent>
          <CardActions>
            <ColorButton variant='outlined' onClick={() => setShowBeginngingModal(false)}>LET'S GO</ColorButton>
          </CardActions>
        </Dialog>
      ) : null}
    </>
  )

}

export default TimeModals
