import { useEffect, useState } from "react"

// Reusable fetching hook
const useRequest = (requestFunc) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const loadItUp = () => {
    setLoading(true)
    requestFunc()
      .then((d) => setData(d))
      .catch((e) => setError(e))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    loadItUp()
  }, [])

  return { data, loading, error, refetch: loadItUp }
}

export default useRequest
