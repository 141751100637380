import React from 'react'
import PropTypes from 'prop-types'
import { BottomNavContainer, BottomNavIcon, IconContainer, SelectionContainer, Label } from './styled'

import cameraIcon from '../../../assets/camera_icon.png'
import photoIcon from '../../../assets/photo_icon.png'
import trophyIcon from '../../../assets/trophy_icon.png'


const BottomNav = (props) => {


    return <BottomNavContainer>
        <div style={{width: 300, display: 'flex', 'justifyContent': 'space-around', position: 'relative'}}>
            <SelectionContainer position={props.currentPage} />
            <IconContainer onClick={() => props.onPageChange(0)} selected={props.currentPage === 0}>
                <BottomNavIcon src={cameraIcon} />
                <Label selected={props.currentPage === 0}>Play</Label>
            </IconContainer>
            <IconContainer onClick={() => props.onPageChange(1)} selected={props.currentPage === 1}>
                <BottomNavIcon src={trophyIcon} />
                <Label selected={props.currentPage === 1}>Leaders</Label>
            </IconContainer>
            <IconContainer onClick={() => props.onPageChange(2)} selected={props.currentPage === 2}>
                <BottomNavIcon src={photoIcon} />
                <Label selected={props.currentPage === 2}>Photos</Label>
            </IconContainer>
        </div>
    </BottomNavContainer>
}

BottomNav.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired
}

export default BottomNav