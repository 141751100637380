import styled from 'styled-components'

export const EventWelcomeContainer = styled.div`
    text-align: left;
    padding: 20px;
    margin: 12px;

    width: 500px;
    background-image: linear-gradient(to bottom right, #8A2BE2, #9932CC);
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    border-radius: 20px;
    color: white;
`

export const EventTitleContainer = styled.div`
    text-align: center;
`

export const EventWelcomeContainerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

export const TeamSelectionContainer = styled.div`
    text-align: center;
    padding: 20px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
`

export const TeamListItem = styled.div`
    ${p => p.selected ? 'background-image: linear-gradient(to bottom right, #8A2BE2, #9932CC);' : 'background-color: white;'}
    height: ${p => p.selected ? '60px' : '50px'};
    width: ${p => p.selected ? '170px' : '150px'};
    color: ${p => p.selected ? 'white': 'black'};
    border-radius: ${p => p.selected ? '20' : '15'}px;
    transition: 0.2s;
    margin: 5px;
    font-size: ${p => p.selected ? '1.4rem' : '1.2rem'};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    padding-left: 8px;
    padding-right: 8px;
`

export const TeamListGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px;
`

export const TeamActionContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 12px;
    align-items: center;
`

export const CustomTeamInput = styled.input`
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    outline: none;
    height: 50px;
    font-size: 16px;
    margin: 15px;
    width: 154px;
`