import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { AllEventsContainer, CurrentEventCard, CurrentEventsContainer, TitleContainer } from './styled'
import TimeAgo from 'react-timeago'

import InputAdornment from '@mui/material/InputAdornment';
import { Button, CardActions, CardContent, CardHeader, Table, TableCell, TableRow, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../utils/useRequest';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#8A2BE2'),
  backgroundColor: '#8A2BE2',
  '&:hover': {
    backgroundColor: '#9932CC',
  },
}));

const getColors = (index) => [['#ec9f0588', '#ff4e0088'], ['#e58c8a88', '#eec0c888'], ['#80ff7288', '#7ee8fa88'], ['#7ee8fa88', '#eec0c688']][index % 4]

const JoinEvent = () => {
  const navigate = useNavigate()
  const { data: events, loading: eventsLoading, error } = useRequest(
    () => fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Events`).then(r => r.json()).then(r => r.data)
  )
  const [filterText, setFilterText] = useState('')

  const getCurrentEvents = () => {
    return (events ?? [])
      .filter((event) => new Date(event.end_time) > new Date())
      .slice(0, 11)
      .map((event, index) => <CurrentEventCard colors={getColors(index)}>
        <CardHeader title={event.name} />
        <CardContent>
          <Typography>{event.organizer}</Typography>
          <Typography><TimeAgo date={new Date(event.start_time)} /></Typography>
        </CardContent>
        <CardActions>
          <Button variant='outlined' color='secondary' onClick={() => navigate(`/join-team/${event.slug}`)}>
            Join Event
          </Button>
        </CardActions>
      </CurrentEventCard>)
  }

  const getEventList = () => {
    return <Table>
      {(events ?? []).filter(e => e.name.toLowerCase().includes(filterText.toLowerCase())).map((event) => <TableRow>
        <TableCell>{event.name}</TableCell>
        <TableCell><TimeAgo date={new Date(event.start_time)} /></TableCell>
        <TableCell><ColorButton variant='contained' onClick={() => navigate(`/join-team/${event.slug}`)}>Join</ColorButton></TableCell>
      </TableRow>)}
    </Table>
  }

  return <>
    <TitleContainer>
      <Typography variant='h3'>Join a Hunt</Typography>
    </TitleContainer>
    {eventsLoading
      ? (<Typography>Loading events...</Typography>)
      : (
        <>
          <CurrentEventsContainer>
            {getCurrentEvents()}
          </CurrentEventsContainer>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <AllEventsContainer>
              <TextField
                fullWidth
                label='Search'
                variant='filled'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setFilterText(event.target.value)}
              />
              {getEventList()}
            </AllEventsContainer>
          </div>
        </>
      )
    }
  </>
}

export default JoinEvent