import styled from 'styled-components'

export const QuestionDetailsContainer = styled.div`
    padding: 22px;
`

export const ImageContainer = styled.div`
    margin: 17px;
    border-radius: 12px;
    overflow: hidden;
`