import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { PhotoContent, TeamContainer } from './styled'
import FlipMove from 'react-flip-move'

import AnnotatedImage from './AnnotatedImage';
import { Pill, PillContainer, PillText, Slider } from '../EventPlay/styled';
import { Divider, Typography } from '@mui/material';

const FunctionalArticle = forwardRef((props, ref) => (
    <div ref={ref}>
        {props.children}
    </div>
));

const ViewPhotos = (props) => {
    const { teams, questions, answers, loading, myTeam } = props;
    const [activePage, setActivePage] = useState(0)

    window.setActivePage = () => setActivePage(activePage === 1 ? 0 : 1)

    const buildPhotosByTeam = () => {
        const { myTeamAnswers, otherTeamAnswers } = answers.reduce((acc, answer) => {
            if (answer.team_id === myTeam.id) {
                acc.myTeamAnswers.push(answer)
            } else {
                acc.otherTeamAnswers.push(answer)
            }
            return acc
        }, { myTeamAnswers: [], otherTeamAnswers: [] })

        const MyTeamHeader = () => <div style={{ padding: 12, color: 'gray' }}>
            <Typography>{'My Team'}</Typography>
            <Divider />
        </div>

        const OtherTeamHeaders = () => <div style={{ padding: 12, color: 'gray' }}>
            <Typography>{'Other Teams'}</Typography>
            <Divider />
        </div>

        return <>
            <TeamContainer>
                {[<FunctionalArticle key={'my team header'}><MyTeamHeader /></FunctionalArticle>]
                    .concat(myTeamAnswers.length ? myTeamAnswers.sort((a, b) => questions.find(q => q.id === a.question_id).title > questions.find(q => q.id === b.question_id).title ? 1 : -1).map(answer => <FunctionalArticle key={answer.id}>
                        <AnnotatedImage key={answer.id} answer={answer} team={myTeam} questionText={questions.find(q => q.id === answer.question_id).instruction} />
                    </FunctionalArticle>) : "Your team's photos will show up here")
                    .concat(<FunctionalArticle key={'other team header'}><OtherTeamHeaders /></FunctionalArticle>)
                    .concat(otherTeamAnswers.length ? teams.filter(t => t.id !== myTeam.id).map(team => answers
                        .filter(answer => answer.team_id === team.id)
                        .sort((a, b) => questions.find(q => q.id === a.question_id).title > questions.find(q => q.id === b.question_id).title ? 1 : -1)
                        .map(answer => <FunctionalArticle key={answer.id}>
                            <AnnotatedImage key={answer.id} answer={answer} team={team} questionText={questions.find(q => q.id === answer.question_id).instruction} />
                        </FunctionalArticle>)) : "Other team's photos will show up here")
                }
            </TeamContainer>
        </>
    }

    const buildPhotosByQuestion = () => {
        return questions.sort((a, b) => a.title > b.title ? 1 : -1).map(question => <TeamContainer>
            {answers
                .filter(answer => answer.question_id === question.id)
                .map(answer => <FunctionalArticle key={answer.id}>
                    <AnnotatedImage key={answer.id} answer={answer} questionText={question.instruction} team={teams.find(t => answer.team_id === t.id)} />
                </FunctionalArticle>)
            }
        </TeamContainer>)
    }

    const buildPhotosByTime = () => {
        return answers.sort((a, b) => new Date(a.created_time) > new Date(b.created_time) ? -1 : 1).map(answer => <FunctionalArticle key={answer.id}>
            <AnnotatedImage key={answer.id} answer={answer} questionText={questions.find(q => q.id === answer.question_id).instruction} team={teams.find(t => answer.team_id === t.id)} />
        </FunctionalArticle>)
    }


    return <>
        <div style={{ width: '100%', maxWidth: '400px', display: 'flex', justifyContent: 'center', paddingTop: 25 }}>
            <div>
                Sort by
                <PillContainer>
                    <Pill>
                        <PillText onClick={() => setActivePage(0)}>Team</PillText>
                        <PillText onClick={() => setActivePage(1)}>Question</PillText>
                        <PillText onClick={() => setActivePage(2)}>Time</PillText>
                    </Pill>
                    <Slider activePage={activePage} />
                </PillContainer>
            </div>
        </div>
        {!loading ?
            <div style={{ width: '100%', maxWidth: '400px', display: 'flex', justifyContent: 'center' }}>
                <PhotoContent>
                    <FlipMove>
                        {activePage === 0 ? buildPhotosByTeam() : activePage === 1 ? buildPhotosByQuestion() : buildPhotosByTime()}
                    </FlipMove>
                </PhotoContent>
            </div>
            : "loading..."
        }
    </>

}

ViewPhotos.propTypes = {
    questions: PropTypes.array,
    teams: PropTypes.array,
    loading: PropTypes.bool,
    answers: PropTypes.array,
    myTeam: PropTypes.object
}

export default ViewPhotos