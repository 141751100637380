import React from 'react'
import PropTypes from 'prop-types'
import { BaseImage, Container, DescriptionContainer, ImageContainer, TitleContainer } from './styled'
import { Typography } from '@mui/material';
import { InitialContainer } from '../../EventPlay/LeaderboardListItem/styled';



const AnnotatedImage = (props) => {

    // const [ hearted, setHearted ] = useState(JSON.parse(localStorage.getItem(`heart_${props.answer.image}`)) || {})

    const imgUrl = `${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/assets/${props.answer.photo}?fit=cover&height=400&width=400`

    // const handleHeartClick = () => {
    //     if (hearted.value) {
    //         localStorage.setItem(`heart_${props.answer.image}`, JSON.stringify({ value: false }))
    //         setHearted({ value: false })
    //     } else {
    //         localStorage.setItem(`heart_${props.answer.image}`, JSON.stringify({ value: true }))
    //         setHearted({ value: true })
    //     }
    // }

    const hexString = (string) => {
        let hex = '';
        for (var i = 0; i < string.length; i++) {
            hex += '' + string.charCodeAt(i).toString(16);
        }
        return hex;
    }

    const getContrast = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? true : false;
    }

    const color = `#${hexString(props.team.id).slice(0, 6)}`

    return <Container>
        <TitleContainer>
            <InitialContainer color={color} dark={getContrast(color)}>{props.team.name[0]}</InitialContainer>
            <Typography style={{ marginLeft: 10 }}>{props.team.name}</Typography>
        </TitleContainer>
        <ImageContainer >
            <BaseImage src={imgUrl} />
        </ImageContainer>
        <DescriptionContainer>

            <Typography>{props.questionText}</Typography>
        </DescriptionContainer>
    </Container>

}

AnnotatedImage.propTypes = {
    answer: PropTypes.object.isRequired,
    questionText: PropTypes.string.isRequired,
    team: PropTypes.object.isRequired,
}

export default AnnotatedImage