import React, { useState, useEffect } from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';

import JoinCustomTeamButton from './JoinCustomTeamButton'

import { EventTitleContainer, EventWelcomeContainer, EventWelcomeContainerContainer, TeamActionContainer, TeamListGroup, TeamListItem, TeamSelectionContainer } from './styled';
import useRequest from '../../utils/useRequest';
import { useNavigate, useParams } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#8A2BE2'),
  backgroundColor: '#8A2BE2',
  '&:hover': {
    backgroundColor: '#9932CC',
  },
  "&:disabled": {
    backgroundColor: 'lightgray'
  }
}));

const JoinTeam = () => {
  const { eventSlug } = useParams()
  const navigate = useNavigate()

  const { data: event, loading: eventLoading, error: eventError } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Events?filter={"slug":{"_eq":"${eventSlug}"}}&include=*.*`)
      .then(r => r.json())
      .then(r => r.data[0])
  })

  const { data: teams, loading: teamsLoading, error: teamsError, refetch } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Teams?filter={"event_id":{"slug":{ "_eq":"${eventSlug}"}}}&include=*.*.*`)
      .then(r => r.json())
      .then(r => r.data)
  })

  const [selectedTeam, setSelectedTeam] = useState(null)
  const [customTeamName, setCustomTeamName] = useState('')
  const [customTeamCreating, setCustomTeamCreating] = useState(false)

  useEffect(() => {
    setInterval(() => refetch(), 5000)
  }, [])

  const getTeamList = () => {
    return teams?.map(team =>
      <TeamListItem key={team.id} onClick={() => setSelectedTeam(team.id)} selected={selectedTeam === team.id}>{team.name}</TeamListItem>
    )
  }

  const joinRandomTeam = () => {
    const team = teams[Math.floor(Math.random() * teams.length)].id
    setSelectedTeam(team)
  }

  const startGame = async () => {
    if (selectedTeam !== 'custom') {
      navigate(`/play-event/${eventSlug}/${teams.find(team => team.id === selectedTeam).slug}`)
      return
    }

    setCustomTeamCreating(true)
    const { slug } = await fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Teams`, {
      method: "POST",
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        event_id: event?.id,
        name: customTeamName,
        slug: customTeamName.toLowerCase().replace(/ /g, '-')
      })
    }).then(r => r.json()).then(r => r.data)
    navigate(`/play-event/${eventSlug}/${slug}`)
    setCustomTeamCreating(false)
  }

  if (eventLoading) {
    return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /><Typography>Loading Event...</Typography></div>
  }

  return <>
    <EventWelcomeContainerContainer>
      <EventWelcomeContainer>
        <EventTitleContainer>
          <Typography variant='h4'>{event?.name ?? ''}</Typography>
        </EventTitleContainer>
        <div dangerouslySetInnerHTML={{ __html: event?.rules }} />
      </EventWelcomeContainer>
    </EventWelcomeContainerContainer>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <TeamSelectionContainer>
        <Typography variant='h4'>Choose a Team</Typography>
        {!!teams && teams.length > 1 ? (<TeamActionContainer>
          <ColorButton variant='outlined' onClick={joinRandomTeam}>
            Random team
          </ColorButton>
        </TeamActionContainer>) : null}
        <TeamListGroup>
          {getTeamList()}

          {!eventLoading && event && event?.allow_own_teams && (
            <JoinCustomTeamButton
              setCustomTeamName={(e) => setCustomTeamName(e.target.value)}
              selected={selectedTeam === 'custom'}
              onClick={() => setSelectedTeam('custom')}
            />
          )}
        </TeamListGroup>
        <ColorButton variant='outlined' disabled={!selectedTeam || customTeamCreating} onClick={() => startGame()}>Start Hunting</ColorButton>
      </TeamSelectionContainer>
    </div>
  </>
}

export default JoinTeam
