import styled from 'styled-components'

export const QuestionContainer = styled.div`
    ${p => p.answered ? 'background-image: linear-gradient(to bottom right, #7ee8fa88, #eec0c688);' : 'background-color: white;'}
    margin: 4px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    padding: 12px 16px;
    position: relative;
`

export const QuestionBackgroundImage = styled.div`
    ${p => p.answered ? 'background-image: url("' + p.answered + '");' : 'background-color: white;'}
    opacity: ${p => p.answered ? 0.6 : 1};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 0;
    background-size: cover;
`

export const QuesitonContentsContainer = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;
`