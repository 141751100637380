import styled from 'styled-components'

export const Container = styled.div`
    height: 50px;
    display: flex;
    padding: 8px;
    border: ${p => p.selected ? '3px solid lightgray' : '3px solid transparent'};
    border-radius: 10px;
    align-items: center;
`

export const InitialContainer = styled.div`
    background-color: ${p => p.color || 'white'};
    color: ${p => p.dark ? 'black' : 'white'};
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 3px solit white;
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    flex-grow: 0;
    transition: 0.2s;
`