import React from 'react'
import PropTypes from 'prop-types'
import { CustomTeamInput, TeamListItem } from './styled'

const JoinCustomTeamButton = (props) => {

    return <TeamListItem selected={props.selected} onClick={props.onClick}>
        { props.selected ? (
            <CustomTeamInput
                type='text'
                autoFocus
                onChange={props.setCustomTeamName}
            />
        ) : (
            'Create Team'
        )}
    </TeamListItem>
}

JoinCustomTeamButton.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    setCustomTeamName: PropTypes.func.isRequired
}

export default JoinCustomTeamButton