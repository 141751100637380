import React from 'react'
import PropTypes from 'prop-types'

// import * as timeago from 'timeago.js'

import { Container, InitialContainer } from './styled'
import ReactTimeago from 'react-timeago'

const LeaderboardListItem = (props) => {

    const hexString = (string) => {
        let hex = '';
        for (var i = 0; i < string.length; i++) {
            hex += '' + string.charCodeAt(i).toString(16);
        }
        return hex;
    }

    const getContrast = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? true : false;
    }

    const color = `#${hexString(props.team.id).slice(0, 6)}`

    const lastDate = props.answers.reduce((acc, answer) => {
        if (!Object.values(acc).length) {
            return answer
        } else if (new Date(answer.updated_date) > new Date(acc.updated_date)) {
            return answer
        }
        return acc
    }, {}).updated_date

    return <Container selected={props.isCurrentTeam}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <InitialContainer color={color} dark={getContrast(color)} >
                <strong>
                    {props.team.name.substring(0, 1)}
                </strong>
            </InitialContainer>
            <div style={{ paddingLeft: 5, paddingRight: 10, flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 4 }}>
                <div style={{ color: 'gray', paddingLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>{props.team.name}</div>
                    <ReactTimeago date={new Date(lastDate)} />
                </div>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 0, left: 0, height: 20, borderRadius: 10, backgroundColor: 'lightgray', width: '100%', zIndex: 0 }} />
                    <div style={{ position: 'absolute', top: 0, left: 0, height: 20, borderRadius: 10, backgroundColor: color, width: `${(props.answers.length * 100) / props.questions.length}%`, zIndex: 2 }} />
                </div>
            </div>
            <div style={{ flexGrow: 0, color: 'gray', display: 'flex', alignItems: 'center' }}>
                {props.answers.length}/{props.questions.length}
            </div>
        </div>
    </Container>

}

LeaderboardListItem.propTypes = {
    team: PropTypes.objectOf({
        name: PropTypes.string
    }).isRequired,
    answers: PropTypes.arrayOf(PropTypes.objectOf({
        title: PropTypes.string,
        image: PropTypes.string
    })).isRequired,
}

export default LeaderboardListItem