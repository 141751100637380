import styled from 'styled-components'

export const QuestionListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: 8px;
    overflow: scroll;

    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
`

export const HeaderContainer = styled.div`
    color: white;
    background-image: linear-gradient(to bottom right, #8A2BE2, #9932CC);
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    padding-bottom: 5px;
    padding-top: 10px;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
    display: flex;
    justify-content: space-around;
    align-items: center;
`

export const Pill = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 15px;
    height: 100%;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color:transparent;
`

export const PillContainer = styled.div`
    width: 300px;
    background: rgb(236, 236, 236);
    border-radius: 50px;
    height: 37px;
    position: relative;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color:transparent;
    &:hover {
        cursor: pointer;
    }
`

export const Slider = styled.div`
    height: 100%;
    width: ${props => props.activePage === 0 ? '30%' : props.activePage === 1 ? '40%' : '30%'};
    background: rgb(201, 201, 201);
    position: absolute;
    border-radius: 50px;
    top: 0px;
    transition: left 0.3s ease 0s;
    left: ${props => props.activePage === 0 ? '0px' : props.activePage === 1 ? '30%' : '70%'};
    transition: .3s;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color:transparent;
`

export const PillText = styled.p`
    z-index: 2;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #424242;

    -webkit-tap-highlight-color:transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
`
export const HeaderLabel = styled.span`
    color: white;
    font-size: 10px;
`