import styled from 'styled-components'

export const BottomNavContainer = styled.div`
    height: 60px;
    background-image: linear-gradient(to bottom right, #8A2BE2, #9932CC);
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    z-index: 3;
    width: 100%;
    overflow: hidden;
    justify-content: space-evenly;
`

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 3;
    width: 100px;
`

export const BottomNavIcon = styled.img`
    width: 40px;
    height: 40px;
    z-index: 7;
`

export const SelectionContainer = styled.div`
    position: absolute;
    top: 0;
    background-color: #F5F5F5;
    z-index: 2;
    left: calc(10px + ${p => p.position * 100}px);
    width: 80px;
    height: 60px;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    transition: .2s;
`

export const Label = styled.span`
    color: ${p => p.selected ? 'purple' : 'white'};
    font-size: 12px;
    transition: .2s;
`