import styled from 'styled-components'

export const AllEventsContainer = styled.div`
    padding: 20px;
    overflow: scroll;
    max-width: 400px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const CurrentEventsContainer = styled.div`
    display: flex;
    padding: 25px;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: stretch;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const CurrentEventCard = styled.div`
    margin: 6px;
    padding: 12px;
    width: 250px;
    border-radius: 20px;
    background-image: linear-gradient(to bottom right, ${p => p.colors[0]}, ${p => p.colors[1]});
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
`

export const TitleContainer = styled.div`
    position: sticky;
    padding: 14px;
    text-align: center;
    background-image: linear-gradient(to bottom right, #8A2BE2, #9932CC);
    box-shadow: 0 7px 30px -10px rgba(150,170,180,0.5);
    color: white;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    top: 0;
`

export const CardSection = styled.div`
    padding: 8;
`
