import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet'

import JoinEvent from './components/JoinEvent';
import JoinTeam from './components/JoinTeam';
import EventPlay from './components/EventPlay';
import ViewPhotos from './components/ViewPhotos';
import CreateEvent from './components/CreateEvent';

const routes = createBrowserRouter([
  {
    path: '/play-event/:eventSlug/:teamSlug',
    element: <EventPlay />
  }, {
    path: '/join-team/:eventSlug',
    element: <JoinTeam />
  }, {
    path: '/view-photos/:eventSlug',
    element: <ViewPhotos />
  }, {
    path: '/',
    element: <JoinEvent />
  },
])

const App = ({ children }) => {
  return <>
    <Helmet>
      <title>Scavengor --- For the HUNT</title>
      <meta name="title" content="Scavengor --- For the HUNT" />
      <meta name="description" content="Host and play free (or very low cost) scavenger hunts with friends, coworkers, classmates, or strangers! Remote or together, a HUNT is what you need." />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.scavengor.com" />
      <meta property="og:title" content="Scavengor --- For the HUNT" />
      <meta property="og:description" content="Host and play free (or very low cost) scavenger hunts with friends, coworkers, classmates, or strangers! Remote or together, a HUNT is what you need." />
      <meta property="og:image" content="https://www.scavengor.com/meta.png" />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.scavengor.com" />
      <meta property="twitter:title" content="Scavengor --- For the HUNT" />
      <meta property="twitter:description" content="Host and play free (or very low cost) scavenger hunts with friends, coworkers, classmates, or strangers! Remote or together, a HUNT is what you need." />
      <meta property="twitter:image" content="https://www.scavengor.com/meta.png" />
    </Helmet>
    <RouterProvider router={routes}>{children}</RouterProvider>
  </>

}

export default App;
