import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import QuestionListItem from './QuestionListItem'
import QuestionExpanded from './QuestionExpanded'
import { HeaderContainer, HeaderLabel, QuestionListContainer } from './styled'
import { Divider, Typography } from '@mui/material'
import LeaderboardListItem from './LeaderboardListItem'
import Countdown from 'react-countdown'
import BottomNav from './BottomNav'
import ViewPhotos from '../ViewPhotos/ViewPhotos'
import FlipMove from 'react-flip-move'
import { useParams } from 'react-router-dom'
import useRequest from '../../utils/useRequest'
import TimeModals from './TimeModals'

const FunctionalArticle = forwardRef((props, ref) => (
  <div ref={ref}>
    {props.children}
  </div>
));

const EventPlay = () => {
  const { eventSlug, teamSlug } = useParams()
  const [timeUp, setTimeUp] = useState(false)
  const [notBegun, setNotBegun] = useState(false)

  const { data: event, loading: eventLoading, error: eventError } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Events?filter={"slug":{"_eq":"${eventSlug}"}}&include=*.*.*,Questions.*,Questions.Answers.*`)
      .then(r => r.json())
      .then(r => r.data[0])
  })

  const { data: questions, loading: questionsLoading, error: questionsError } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Questions?filter={"event_id":{"slug":{ "_eq":"${eventSlug}"}}}&limit=-1`)
      .then(r => r.json())
      .then(r => r.data)
  })

  const { data: otherTeams, loading: otherTeamsLoading, error: otherTeamsError, refetch: getOtherTeams } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Teams?filter={"event_id":{"slug":{ "_eq":"${eventSlug}"}}}&include=*.*.*,Answers.*.*,Answers.question_id.*`)
      .then(r => r.json())
      .then(r => r.data)
  })

  const { data: allAnswers, loading: answersLoading, error: answersError, refetch: answerRefetch } = useRequest(() => {
    return fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Answers?filter={"team_id":{"event_id":{"slug":{ "_eq":"${eventSlug}"}}}}&include=*.*.*&limit=-1`)
      .then(r => r.json())
      .then(r => r.data)
  })

  const team = useMemo(() => otherTeams && otherTeams.find(team => team.slug === teamSlug), [otherTeams, teamSlug])
  const answers = useMemo(() => team ? (allAnswers ?? []).filter((a) => a.team_id === team?.id) : [], [team, allAnswers])

  const [activePage, setActivePage] = useState(0)
  const [showOtherPage, setShowOtherPage] = useState(0)

  const [expandedQuestion, setExpandedQuestion] = useState(null)
  const [notes, setNotes] = useState(JSON.parse(localStorage.getItem(`notes_${eventSlug}`)) || {})

  const [answerUploading, setAnswerUploading] = useState(false)

  useEffect(() => {
    setInterval(() => {
      if (!document.hidden) {
        getOtherTeams()
        answerRefetch()
      }
    }, 10000)
  }, [])

  const expandQuestion = (id) => {
    setExpandedQuestion(questions.find(question => question.id === id))
  }

  const leaveNote = (question_id) => (answer) => {
    localStorage.setItem(`notes_${eventSlug}`, JSON.stringify({ ...notes, [question_id]: answer }))
    setNotes({ ...notes, [question_id]: answer })
  }

  const generateQuestions = () => {
    const { answered, unanswered } = (questions ?? []).reduce((acc, question) => {
      const answer = answers && answers.length && answers.find(answer => answer.team_id === team?.id && question.id === answer.question_id)
      const photoUrl = answer && `${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/assets/${answer.photo}?fit=cover&height=400&width=400`

      if (answer) {
        acc.answered.push({
          question,
          answer,
          photoUrl
        })
      } else {
        acc.unanswered.push(question)
      }
      return acc
    }, { answered: [], unanswered: [] })

    const IncompleteLabel = () => <div style={{ padding: 12, color: 'gray' }}>
      <Typography>{'Incomplete'}</Typography>
      <Divider />
    </div>
    const NoIncompleteMessage = () => <div style={{ color: '#404040', maxWidth: 400, height: 25, padding: 5, paddingLeft: 20, paddingRight: 20, textAlign: 'center', verticalAlign: 'middle' }}>You're all done! Go back to the meeting point</div>
    const CompleteLabel = () => <div style={{ padding: 12, color: 'gray' }}>
      <Typography>{'Complete'}</Typography>
      <Divider />
    </div>
    const NoCompleteMessage = () => <div style={{ color: '#404040', maxWidth: 400, height: 25, padding: 5, paddingLeft: 20, paddingRight: 20, textAlign: 'center', verticalAlign: 'middle' }}>Completed tasks will show up here</div>

    return <>
      <FlipMove>
        {
          [<FunctionalArticle key={'not done label'}><IncompleteLabel /></FunctionalArticle>]
            .concat(unanswered.length
              ? unanswered.sort((a, b) => a.title > b.title ? 1 : -1)
                .map(question => <FunctionalArticle key={question.id}><QuestionListItem note={notes[question.id] || ''} leaveNote={leaveNote(question.id)} question={question} onClick={() => expandQuestion(question.id)} /></FunctionalArticle>)
              : <FunctionalArticle key={'no imcomplete message'} ><NoIncompleteMessage /></FunctionalArticle>)
            .concat(<FunctionalArticle key={'done label'}><CompleteLabel key={'CompleteLabel'} /></FunctionalArticle>)
            .concat(answered.length
              ? answered.sort((a, b) => a.question.title > b.question.title ? 1 : -1)
                .map(({ question, answer, photoUrl }) => <FunctionalArticle key={question.id}><QuestionListItem note={notes[question.id] || ''} leaveNote={leaveNote(question.id)} question={question} answer={answer} photoUrl={photoUrl} onClick={() => expandQuestion(question.id)} /></FunctionalArticle>) : <FunctionalArticle key={'no complete message'} ><NoCompleteMessage /></FunctionalArticle>)
        }
      </FlipMove>
    </>
  }

  const replacePhoto = async (file) => {
    setAnswerUploading(true)

    const existingAnswer = answers.find(answer => answer.question_id === expandedQuestion.id && answer.team_id === team?.id)

    try {
      const formData = new FormData()
      formData.append('storage', 'gcloud')
      formData.append('filename_download', 'scavengor_upload')
      formData.append('file', file)

      await fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/files`, {
        method: "POST",
        body: formData
      }).then(r => r.json()).then(r => r.data).then(({ id: responseId }) => fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Answers/${existingAnswer.id}`, {
        method: "PATCH",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          photo: responseId
        })
      }))
      answerRefetch()
    } catch (err) {
      console.error(err)
    } finally {
      setAnswerUploading(false)
    }
  }

  const submitAnswer = async (file) => {
    setAnswerUploading(true)
    try {

      const formData = new FormData()
      formData.append('storage', 'gcloud')
      formData.append('filename_download', 'scavengor_upload')
      formData.append('file', file)

      await fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/files`, {
        method: "POST",
        body: formData
      }).then(r => r.json()).then(r => r.data).then(({ id: responseId }) => fetch(`${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/items/Answers`, {
        method: "POST",
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({
          photo: responseId,
          team_id: team?.id,
          question_id: expandedQuestion.id,
          event_id: event?.id,
        })
      }))
      answerRefetch()
      setExpandedQuestion(null)
    } catch (err) {
      console.error(err)
    } finally {
      setAnswerUploading(false)
    }
  }

  const generateLeaderboard = () => {
    const teamsAndAnswers = (otherTeams ?? []).map(team => {
      const teamAnswers = Object.values((allAnswers ?? []).filter(answer => answer.team_id === team.id).reduce((acc, answer) => {
        if (acc[answer.question_id]) {
          return { ...acc, [answer.question_id]: [answer, acc[answer.question_id]].sort((a, b) => (Date(b.updated_at) - Date(a.updated_at)))[0] }
        }
        return { ...acc, [answer.question_id]: answer }
      }, []))
      return {
        team,
        answers: teamAnswers
      }
    }).sort((a, b) => {
      if (a.answers.length === b.answers.length) {
        const mostRecentA = a.answers.reduce((acc, answer) => {
          if (!Object.keys(acc).length) {
            return answer
          }
          if (new Date(answer.updated_at) > new Date(acc.updated_at)) {
            return answer
          }
          return acc
        }, {})
        const mostRecentB = b.answers.reduce((acc, answer) => {
          if (!Object.keys(acc).length) {
            return answer
          }
          if (new Date(answer.updated_at) > new Date(acc.updated_at)) {
            return answer
          }
          return acc
        }, {})
        return new Date(mostRecentA.updated_at) - new Date(mostRecentB.updated_at)
      }
      return a.answers.length > b.answers.length ? -1 : 1
    })

    return <FlipMove>
      {teamsAndAnswers
        .map(theseProps => <FunctionalArticle key={theseProps.team.id}>
          <LeaderboardListItem isCurrentTeam={theseProps.team.slug === teamSlug} {...theseProps} questions={questions} />
        </FunctionalArticle>)
      }
    </FlipMove>
  }

  const expandedAnswer = expandedQuestion && answers && answers.length && answers.find(answer => answer.question_id === expandedQuestion.id && answer.team_id === team?.id)
  const expandedPhotoUrl = expandedAnswer ? `${process.env.REACT_APP_API_URL || 'https://scavengor-directus.cloud.plodamouse.com'}/assets/${expandedAnswer.photo}` : null

  const showActivePage = (index) => {
    setShowOtherPage(activePage)
    setTimeout(() => setShowOtherPage(index), 200)
    setActivePage(index)
  }

  const answersCompleted = (answers ?? [])
    .filter(answer => answer.team_id === team?.id)
    .reduce((acc, answer) => acc.includes(answer.question_id) ? acc : acc.concat(answer.question_id), [])
    .length


  return <>
    <HeaderContainer>
      <div>
        <HeaderLabel>TEAM</HeaderLabel>
        <Typography variant='h6'>{team && team.name ? team.name : ''}</Typography>
      </div>
      <div>
        <HeaderLabel>TIME LEFT</HeaderLabel>
        <div>
          <Countdown date={new Date(event?.end_time)} />
        </div>
      </div>
      <div>
        <HeaderLabel>TASKS</HeaderLabel>
        <Typography>{answersCompleted}/{(questions ?? []).length}</Typography>
      </div>
    </HeaderContainer>

    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: "scroll", minHeight: '100%' }}>
      <div style={{ width: activePage === 0 ? '100%' : 0, maxWidth: 400, display: 'flex', justifyContent: 'center', height: '100%', transition: '.2s', overflow: 'hidden' }}>
        <QuestionListContainer style={{ height: '100%' }}>
          {activePage === 0 || showOtherPage === 0 ? generateQuestions() : null}
        </QuestionListContainer>
      </div>
      <div style={{ width: activePage === 1 ? '100%' : 0, maxWidth: 400, display: 'flex', justifyContent: 'center', height: '100%', transition: '.2s', overflow: 'hidden' }}>
        <QuestionListContainer>
          {activePage === 1 || showOtherPage === 1 ? generateLeaderboard() : null}
        </QuestionListContainer>
      </div>
      <div style={{ width: activePage === 2 ? '100%' : 0, maxWidth: 400, display: 'flex', justifyContent: 'center', height: '100%', transition: '.2s', overflow: 'hidden' }}>
        <QuestionListContainer>
          {activePage === 2 || showOtherPage === 2 ? <ViewPhotos teams={otherTeams} answers={allAnswers ?? []} questions={questions ?? []} myTeam={team} /> : null}
        </QuestionListContainer>
      </div>
    </div>

    <BottomNav currentPage={activePage} onPageChange={showActivePage} />

    <QuestionExpanded
      show={!!expandedQuestion}
      note={expandedQuestion ? notes[expandedQuestion.id] : ''}
      leaveNote={expandedQuestion ? leaveNote(expandedQuestion.id) : () => { }}
      question={expandedQuestion}
      close={() => setExpandedQuestion(null)}
      submitAnswer={submitAnswer}
      photoUrl={expandedPhotoUrl}
      uploading={answerUploading}
      timeUp={timeUp}
      notBegun={notBegun}
      onReplaceImage={(file) => replacePhoto(file)}
    />

    <TimeModals event={event} showActivePage={showActivePage} setTimeUp={setTimeUp} setNotBegun={setNotBegun} />
  </>

}

export default EventPlay