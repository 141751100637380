import React from 'react'
import PropTypes from 'prop-types'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';

import 'react-spring-bottom-sheet/dist/style.css'
import { ImageContainer, QuestionDetailsContainer } from './styled'
import { UploadFileOutlined } from '@mui/icons-material';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#8A2BE2'),
    backgroundColor: '#8A2BE2',
    '&:hover': {
        backgroundColor: '#9932CC',
    },
    "&:disabled": {
        backgroundColor: 'lightgray'
    }
}));

const QuestionExpanded = (props) => {
    const hiddenFileInput = React.useRef(null);
    const hiddenFileInput2 = React.useRef(null);

    const hasExistingPhoto = !!props.photoUrl

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.submitAnswer(fileUploaded)
    };

    const onReplaceImage = event => {
        const fileUploaded = event.target.files[0];
        props.onReplaceImage(fileUploaded)
    }

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleUploadClick = () => {
        hiddenFileInput2.current.click();
    }

    const getCurrentButton = () => {
        if (props.timeUp) {
            return (
                <ColorButton disabled={true} onClick={handleClick} variant='outlined' fullWidth>
                    Time is up
                </ColorButton>
            )
        } else if (props.notBegun) {
            return (
                <ColorButton disabled={true} onClick={handleClick} variant='outlined' fullWidth>
                    Wait for start
                </ColorButton>
            )
        } else if (props.photoUrl) {
            return (
                <ColorButton disabled={props.uploading} onClick={handleClick} variant='outlined' fullWidth>
                    {props.uploading ? <CircularProgress /> : 'Replace Photo'}
                </ColorButton>
            )
        } else {
            return (
                <ColorButton disabled={props.uploading} onClick={handleClick} variant='outlined' fullWidth>
                    {props.uploading ? <CircularProgress /> : 'Take Photo'}
                </ColorButton>
            )
        }
    }


    return (
        <BottomSheet
            open={props.show}
            onDismiss={props.close}
            defaultSnap={({ minHeight }) => props.photoUrl ? 400 : minHeight}
            snapPoints={({ minHeight, maxHeight }) => props.photoUrl ? [200, 400, maxHeight - 80] : [200, minHeight, maxHeight - 80]}
            header={
                props.show && props.question ? <Typography>{props.question.title}</Typography> : null
            }
            footer={
                <div style={{ display: 'flex' }}>
                    {getCurrentButton()}
                    <Tooltip title={'Upload existing'}>
                        <IconButton disabled={props.uploading || props.timeUp || props.notBegun} onClick={handleUploadClick}>
                            <UploadFileOutlined htmlColor='#8A2BE2' />
                        </IconButton>
                    </Tooltip>

                </div>
            }
        >
            {props.show && props.question && (
                <div style={{ opacity: props.uploading ? 0.5 : 1 }}>
                    <QuestionDetailsContainer>
                        <div dangerouslySetInnerHTML={{ __html: props.question.riddle }} />
                        <Typography variant='subtitle1'><strong>{props.question.instruction}</strong></Typography>
                        <TextField
                            style={{ marginTop: 17 }}
                            label="Leave a note to remember this task"
                            autoFocus={false}
                            fullWidth
                            variant='outlined'
                            onChange={(event) => props.leaveNote(event.target.value)}
                            value={props.note}
                        />
                        {props.photoUrl ? <ImageContainer>
                            <img alt="" src={props.photoUrl} width='100%' />
                        </ImageContainer> : null}
                    </QuestionDetailsContainer>
                </div>
            )}
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={!hasExistingPhoto ? handleChange : onReplaceImage}
                style={{ display: 'none' }}
                accept="image/*"
                capture
            />
            <input
                type="file"
                ref={hiddenFileInput2}
                onChange={!hasExistingPhoto ? handleChange : onReplaceImage}
                style={{ display: 'none' }}
                accept="image/*"
            />
        </BottomSheet>
    )
}

QuestionExpanded.propTypes = {
    question: PropTypes.object,
    show: PropTypes.bool,
    close: PropTypes.func.isRequired,
    submitAnswer: PropTypes.func.isRequired,
    leaveNote: PropTypes.func.isRequired,
    photoUrl: PropTypes.string,
    uploading: PropTypes.bool,
    timeUp: PropTypes.bool,
    notBegun: PropTypes.bool,
    onReplaceImage: PropTypes.func,
    note: PropTypes.string,
}

export default QuestionExpanded
